import { UpdateClaimRequestInterface } from '@/data/payload/contracts/ClaimRequest'

export class UpdateClaimRequest implements UpdateClaimRequestInterface {
  public claimStatus?: string
  public note?: string
  public actorName?: string
  public actorRole?: string
  public claimAmount?: number

  constructor(
    claimStatus?: string,
    note?: string,
    actorName?: string,
    actorRole?: string,
    claimAmount?: number
  ) {
    this.claimStatus = claimStatus
    this.note = note
    this.actorName = actorName
    this.actorRole = actorRole
    this.claimAmount = claimAmount
  }

  public toPayload() {
    const data = {
      claim_status: this.claimStatus,
      note: this.note,
      actor_name: this.actorName,
      actor_role: this.actorRole,
    }

    if (this.claimAmount) {
      Object.assign(data, {
        claim_amount: this.claimAmount,
      })
    }

    return JSON.stringify(data)
  }
}
