import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  EventBus,
  EventBusConstants,
  LocalStorage,
  Utils,
} from '@/app/infrastructures/misc'
import { UpdateClaimRequest } from '@/data/payload/api/ClaimRequest'
import { ClaimPresenter } from '@/app/ui/presenters/ClaimPresenter'
import { Claim, Claims } from '@/domain/entities/Claim'
import { Pagination } from '@/domain/entities/Pagination'
import { CLAIM_PAGINATION } from '@/app/infrastructures/misc/Constants'

export interface ClaimState {
  isLoading: boolean
  paginationData: Pagination
  claimData: Claim[]
  claimDetail: Claim
}

@Module({ namespaced: true, store, name: 'claim', dynamic: true })
class ClaimController extends VuexModule implements ClaimState {
  private presenter: ClaimPresenter = container.resolve(ClaimPresenter)
  public isLoading = false
  public paginationData = new Pagination(1, CLAIM_PAGINATION)
  public claimData = [new Claim()]
  public claimDetail = new Claim()
  public constants = {
    rejectMessageLength: 90,
    claimStatuses: [
      {
        status: 'PENDING',
        actor: 'CUSTOMER',
        label: "User's Request",
        position: 'past',
      },
      {
        status: 'ONPROGRESS',
        actor: 'CNM',
        label: 'Approved CNM',
        position: 'current',
      },
      {
        status: 'ONPROGRESS',
        actor: 'FINANCE',
        label: 'Approval Finance',
        position: 'future',
      },
      {
        status: 'APPROVED',
        actor: 'FINANCE',
        label: 'Done',
        position: 'future',
      },
      {
        status: 'REJECTED',
        actor: '',
        label: 'Rejected',
        position: 'future',
      },
    ],
  }
  public accountRole = LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true)

  @Action({ rawError: true })
  public updateClaim(form: {
    claimId: string
    claimStatus: string
    note: string
    claimAmount?: number
  }) {
    this.setLoading(true)
    const adminEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    let actorRole = ''
    if (this.accountRole === 'CMS_FINANCE') {
      actorRole = 'FINANCE'
    } else if (this.accountRole === 'CMS_CNM') {
      actorRole = 'CNM'
    } else if (
      this.accountRole === 'CMS_ADMIN' ||
      this.accountRole === 'ADMIN'
    ) {
      actorRole = 'ADMIN'
    } else {
      actorRole = this.accountRole
    }

    this.presenter
      .update(
        form.claimId,
        new UpdateClaimRequest(
          form.claimStatus,
          form.note,
          adminEmail,
          actorRole,
          form.claimAmount
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_CLAIM_SUCCESS, {
          id: form.claimId,
          claimStatus: form.claimStatus,
        })
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Claim Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getClaimList(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setClaimData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Claim Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getClaimDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setClaimDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Claim Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setClaimData(claims: Claims) {
    this.paginationData = claims.pagination as Pagination
    this.claimData = claims.data as Claim[]
  }

  @Mutation
  private setClaimDetail(claim: Claim) {
    this.claimDetail = claim
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }
}

export default getModule(ClaimController)
