


















import {Vue, Component, Prop} from 'vue-property-decorator'

@Component({})
export default class EditIcon extends Vue {
  @Prop({default: '24px'}) width!: string
  @Prop({default: '24px'}) height!: string
}
