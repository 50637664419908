var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-testid":"global-component-data-table-v1"}},[_c('div',{class:[
      'table table-container',
      { 't-fixed': _vm.wideness === 'fixed', 't-auto': _vm.wideness === 'auto' } ]},[_c('div',{staticClass:"table-header-group thead"},[_c('div',{staticClass:"table-row th"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDraggable),expression:"isDraggable"}]}),_vm._l((_vm.headers),function(label,idx){return [(!_vm.isHidden(idx) && typeof label === 'string')?_c('div',{key:idx,class:['table-cell td bg-gray-7 text-gray-4', { 'rounded-l-lg': idx === 0 }],attrs:{"data-testid":"global-component-data-table-v1__header"}},[_vm._v(" "+_vm._s(label)+" ")]):(!_vm.isHidden(idx) && typeof label === 'object')?_c('div',{key:idx,staticClass:"table-cell td",class:label.class,style:(Object.assign({}, {width: label.width, height: label.height}, label.customStyle))},[_vm._v(" "+_vm._s(label.title)+" ")]):_vm._e()]}),(_vm.showViewAction)?_c('div',{staticClass:"table-cell rounded-r-lg bg-gray-7 td"},[_vm._v(" Action ")]):_vm._e(),(_vm.showEditAction)?_c('div',{staticClass:"table-cell td"}):_vm._e()],2)]),_c('Draggable',{staticClass:"table-row-group tbody",attrs:{"draggable":_vm.isDraggable ? '.table-item-row' : undefined},on:{"change":function($event){return _vm.$emit('dropped', $event)}},model:{value:(_vm.tableData),callback:function ($$v) {_vm.tableData=$$v},expression:"tableData"}},_vm._l((_vm.tableData),function(rows,idx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading && !_vm.isDataEmpty),expression:"!isLoading && !isDataEmpty"}],key:idx,class:[
          'table-row tr table-item-row',
          { 'cursor-pointer': _vm.$listeners.rowClick } ],on:{"click":function($event){return _vm.$emit('rowClick', rows)}}},[_c('DragIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDraggable),expression:"isDraggable"}],staticClass:"ml-1 -mr-1 mt-1 -mb-1 cursor-move"}),_vm._l((rows),function(item,ix){return [(!_vm.isHidden(ix))?_c('div',{key:ix,class:[
              'table-cell border-b-2 bg-white td',
              { 'w-36': _vm.normalWidth.includes(ix) }
            ],attrs:{"data-testid":"global-component-data-table-v1__row"}},[(_vm.isHasSlot(ix))?_vm._t(ix,null,{"index":idx,"data":item}):[(_vm.badgeToIndex === ix)?_c('Badge',{staticClass:"w-full w-32-a flex justify-center px-2 py-1 text-center",attrs:{"data-testid":"global-component-data-table-v1__row__badge","type":_vm.getType(item),"customPadding":true}},[_vm._v(_vm._s(item))]):_c('span',{staticClass:"flex flex-row text-sm"},[_vm._v(" "+_vm._s(item)+" ")])]],2):_vm._e()]}),(_vm.showViewAction)?_c('div',{staticClass:"table-cell border-b-2 bg-white td"},[(_vm.actionhasSlot('action'))?_vm._t("action",null,{"data":rows,"idx":idx}):_c('div',{staticClass:"flex flex-col items-start gap-1"},[_c('router-link',{staticClass:"font-semibold whitespace-nowrap text-bgPrimary",attrs:{"data-testid":"global-component-data-table-v1__event-detail","to":("" + _vm.baseLink + (rows[0]))}},[_vm._v("View Detail")]),(
                _vm.showCancelAction &&
                  !_vm.isGuest &&
                  !_vm.hideCancelFlags.includes(rows[_vm.hideCancelIndexReference])
              )?_c('button',{staticClass:"font-semibold whitespace-nowrap text-bgPrimary",attrs:{"data-testid":"global-component-data-table-v1__event-cancel"},on:{"click":function () {
                  _vm.$emit('cancel', rows[0])
                }}},[_vm._v(" Cancel ")]):_vm._e()],1)],2):_vm._e(),(_vm.showEditAction)?_c('div',{staticClass:"table-cell td"},[_c('div',{staticClass:"flex flex-col items-start gap-1"},[_c('router-link',{staticClass:"font-semibold whitespace-nowrap text-bgPrimary",attrs:{"data-testid":"global-component-data-table-v1__event-edit","to":("" + _vm.baseLink + (rows[0]))}},[_c('edit-icon')],1)],1)]):_vm._e()],2)}),0)],1),(_vm.isLoading)?_c('div',{staticClass:"flex flex-col",attrs:{"data-testid":"global-component-data-table-v1__loader"}},[_c('div',{staticClass:"mx-auto py-10 text-gray-600"},[_vm._v("Loading data...")])]):(_vm.isDataEmpty)?_c('div',{staticClass:"flex flex-col",attrs:{"data-testid":"global-component-data-table-v1__empty-state"}},[(_vm.hasSlot('data-empty'))?_vm._t("data-empty"):_c('div',{staticClass:"mx-auto py-10 text-gray-600"},[_vm._v("Data is empty")])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }