







































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import Badge from '@/app/ui/components/Badge/index.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import { Utils } from '@/app/infrastructures/misc'
import EditIcon from '@/app/ui/assets/edit_icon_alt2.vue'

/**
 * DataTable
 *
 * If `isDraggable` set to `true`
 *
 * Parent component must set listener for event `@dropupdate`
 * to update the variable contains current list of data that passed to this component in `data` prop
 *
 *
 * `headers`: string | { title: string, width: string, class: string }
 *
 * If you want to custom width, height, or class name for `headers` you must set the attribute (width, height, class) for the header value
 * example:
 * [{ title: 'No', width: '10% , class: 'text-center' }]
 * otherwise, you can just use 'string' for the value
 * ['No', 'Name']
 *
 *
 * Custom template/scoped field
 * Scoped field slots give you greater control over how the record data appears. You can use scoped slots to provided custom rendering for a particular field.
 * Example: if you want to custom render for the second field
 * <DataTable>
 *   <template #1="{ data }">
 *     <Badge type="active">{{ data }}</Badge>
 *   </template>
 * </DataTable>
 * PS: Index started from 0
 *
 */
@Component({
  components: { Draggable, Badge, DragIcon, EditIcon },
})
export default class DataTable extends Vue {
  isGuest = Utils.isGuest()
  @Prop({ default: false }) private isDraggable!: boolean
  @Prop({ default: false }) private isLoading!: boolean
  @Prop({ default: 'auto' }) private wideness!: 'auto' | 'fixed'
  @Prop({ default: () => [] }) private headers!: Array<
    string | { title: string; width: string; class: string | string[] }
  >
  @Prop({ default: () => [] }) private data!: Array<Array<string>>
  @Prop({ default: false }) private showViewAction!: boolean
  @Prop({ default: false }) private showEditAction!: boolean
  @Prop({ default: false }) private showCancelAction!: boolean
  @Prop({ default: '' }) private baseLink!: string
  @Prop({ default: null }) private badgeToIndex!: number
  @Prop({ default: () => [] }) private hiddenColumnIndexes!: Array<number>
  @Prop({ default: null }) private hideCancelIndexReference!: number
  @Prop({ default: () => [] }) private hideCancelFlags!: Array<string>
  @Prop({ default: () => [] }) private normalWidth!: Array<number>

  get isDataEmpty(): boolean {
    return this.data.length === 0
  }

  get tableData(): Array<string[]> {
    return this.data
  }

  set tableData(v: Array<string[]>) {
    this.$emit('dropupdate', v)
  }

  private getType(status: string) {
    if (status) {
      if (
        status.toLowerCase() === 'active' ||
        status.toLowerCase() === 'published' ||
        status.toLowerCase() === 'done' ||
        status.toLowerCase() === 'approved finance' ||
        status.toLowerCase() === 'approved' ||
        status.toLowerCase() === 'paid' ||
        status.toLowerCase() === 'success'
      ) {
        return 'success'
      } else if (
        status.toLowerCase() === 'pending' ||
        status.toLowerCase() === "user's request" ||
        status.toLowerCase() === 'approved cnm' ||
        status.toLowerCase() === 'request' ||
        status.toLowerCase() === 'non login' ||
        status.toLowerCase() === 'new' ||
        status.toLowerCase() === 'working' ||
        status.toLowerCase() === 'approve' ||
        status.toLowerCase() === 'onprogress' ||
        status.toLowerCase() === 'requested'
      ) {
        return 'warning'
      } else if (status.toLowerCase() === 'deleted') {
        return 'expire'
      } else {
        return 'error'
      }
    }
  }

  private hasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private isHasSlot(indexNumber: number): boolean {
    return !!this.$scopedSlots[indexNumber]
  }

  private actionhasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private isHidden(indexNumber: number) {
    return this.hiddenColumnIndexes.includes(indexNumber)
  }
}
